<template>
    <div class="agreement-container">
        <loginHeader></loginHeader>
        <div class="main">
            <div class="content">
                <h1>title</h1>
                <p>{{text}}</p>
                <div @click="$router.go(-1)" class="btn">close</div>
            </div>
        </div>
    </div>
</template>

<script>
    import loginHeader from "../../components/login/loginHeader";

    const text = "What’s covered in these terms\n" +
        "We know it’s tempting to skip these Terms of Service, but it’s important to establish what you can expect from us as you use Google services, and what we expect from you.\n" +
        "These Terms of Service reflect the way Google’s business works, the laws that apply to our company, and certain things we’ve always believed to be true. As a result, these Terms of Service help define Google’s relationship with you as you interact with our services. For example, these terms include the following topic headings:\n" +
        "\n" +
        "What you can expect from us, which describes how we provide and develop our services\n" +
        "What we expect from you, which establishes certain rules for using our services\n" +
        "Content in Google services, which describes the intellectual property rights to the content you find in our services — whether that content belongs to you, Google, or others\n" +
        "In case of problems or disagreements, which describes other legal rights you have, and what to expect in case someone violates these terms\n" +
        "Understanding these terms is important because, by using our services, you’re agreeing to these terms.\n" +
        "\n" +
        "Besides these terms, we also publish a Privacy Policy. Although it’s not part of these terms, we encourage you to read it to better understand how you can update, manage, export, and delete your information.";
    export default {
        name: "agreement",
        data() {
            return {
                text: text,
            };
        },
        components: {loginHeader,},
        props: {},
    }
</script>

<style scoped>
    .main {
        min-height: calc(100vh - 183px);
    }

    .content {
        width: 853px;
        margin: 56px auto 0;
    }

    .content h1 {
        font-size: 38px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 44px;
        color: #000000;
        text-align: center;
    }

    .content p {
        font-size: 15px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 26px;
        color: #707070;
        margin-top: 46px;
    }

    .content .btn {
        cursor: pointer;
        width: 140px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #707070;
        border-radius: 131px;
        margin: 113px auto 0;
    }
</style>
